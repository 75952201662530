import React from "react";
import { Helmet } from "react-helmet";
import { TwitterCardsPropsTypes } from "./types";

function TwitterCards({ type, username, title, description, image }: TwitterCardsPropsTypes) {
  type = type ?? "summary_large_image";

  return (
    <Helmet>
      {username && <meta name="twitter:creator" content={username} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta name="twitter:image:alt" content={description} />}
    </Helmet>
  )
}

export default TwitterCards;