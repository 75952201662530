import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react'
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { LineItemType } from '../../../components/templates/product/types';
import { objectifyFormData } from '../../../helpers/form';
import httpRequest from '../../../helpers/http';
import CartStorage from '../../../helpers/storage';
import { validateBillingDetails } from '../../../helpers/validator/checkout';
import { useCart } from '../../../hooks/useCart';
import useFreshShipping from '../../../hooks/useShipping';
import { NodeEdges, ProductType, ShippingLineType, ShippingZoneType } from '../../../types/global';
import CheckoutPageStaticQueryType from '../../../types/pages/checkout';

export default function Checkout() {
  const [state, dispatch] = useCart();
  const alertType = !!state.message ? "success" : "danger";
  const {
    websiteDetails,
    storeShipping,
    site: {
      buildTime,
      siteMetadata: {
        checkout_url
      }
    },
  } = useStaticQuery<CheckoutPageStaticQueryType>(graphql`
    query CheckoutPage {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          checkout_url
        }
      }
      websiteDetails: zzStoreWebsiteDetails {
				store_checkout_url
        store_shipping
      }
      storeShipping: allZzStoreShipping(sort: {fields: [zzenz_id], order: ASC}) {
        edges {
          node {
            zzenz_id
            name
            cost
            location {
              code
              type
              name
            }
          }
        }
      }
    }
  `);
  const [shippingState] = useFreshShipping(websiteDetails.store_shipping, storeShipping.edges);
  const buildDates = {
    first_publication_date: buildTime,
    last_publication_date: buildTime,
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });

    const { currentTarget: form } = event;
    const { action, method } = form;
    const formData = new FormData(form);
    const { shipping, billing } = objectifyFormData(formData);
    const errors = validateBillingDetails(billing as Record<string, string>);

    if (errors !== false) {
      dispatch({
        type: "ERROR",
        payload: errors
      });
    } else {
      const stateLocation = storeShipping.edges.find(({ node }) => {
        return node.location.code === (billing as any)?.state;
      });
      if ((billing as any)?.state) {
        (billing as any).state = stateLocation?.node.location.name;
        (shipping as any).state = stateLocation?.node.location.name;
      }
      if (!!state.data.shippingLines?.[0]) {
        delete state.data.shippingLines?.[0].id;
      }
      const order = {
        line_items: state.data.lineItems,
        billing,
        shipping,
        shipping_lines: state.data.shippingLines,
      };

      try {
        const response = await httpRequest({
          url: action,
          requestConfig: {
            method,
            body: JSON.stringify(order),
            headers: {
              "Content-Type": "application/json"
            }
          }
        });

        if (response?.code === "created") {
          dispatch({
            type: "SUCCESS",
            payload: response.message
          });
          const cart = new CartStorage();
          cart.clearshoppingCart();
          if (typeof window !== "undefined") {
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top: 150
            });
          }
        } else {
          dispatch({
            type: "ERROR",
            payload: response.errors
          });
        }
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: {
            global: "Une erreur s'est produite. Veuillez réessayer!"
          }
        });
      }
    }
  }
  const onEditShipping = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const selectedShippind = shippingState.data.find(({ node: { location } }: NodeEdges<ShippingZoneType>) => location.code === value);

    if (selectedShippind !== undefined) {
      const cart = new CartStorage();
      const shippingLine = {
        method_id: "flat_rate",
        id: selectedShippind?.node?.zzenz_id,
        method_title: selectedShippind?.node.name,
        total: selectedShippind?.node.cost
      };
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "shippingLines",
          value: [shippingLine]
        }
      });
      await cart.updateShippingLine(shippingLine);
    }
  }
  const getShippingLocation = () => {
    const selectedShippind = storeShipping.edges.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data.shippingLines?.[0]?.id;
    });
    return selectedShippind?.node?.location?.code;
  }
  const getLineItemsTotal = () => {
    const totalPrice = state.data?.lineItems?.reduce((acc: number, { product_id, quantity }: LineItemType) => {
      const { regular_price } = state.data.products[product_id];
      return acc + (regular_price * quantity);
    }, 0);

    return totalPrice;
  }
  const getShippingTotal = () => {
    if (state.data.shippingLines.length > 0) {
      return parseFloat(state.data.shippingLines?.[0]?.total) || 0;
    } else {
      return parseFloat(String(storeShipping.edges?.[0]?.node.cost)) || 0;
    }
  }

  useEffect(() => {
    (async () => {
      const cart = new CartStorage();
      const oldShipping = await cart.getShippingLines() as [];

      if (state.data.shippingLines?.length === 0 && oldShipping.length === 0) {
        const zone = storeShipping.edges?.[0]?.node;
        const shippingLines: ShippingLineType = {
          id: zone?.zzenz_id,
          method_id: "flat_rate",
          method_title: zone?.name,
          total: zone?.cost
        };
        dispatch({
          type: "SET_FIELD",
          payload: {
            key: "shippingLines",
            value: shippingLines
          }
        });
        await cart.updateShippingLine(shippingLines);
      }
    })();
  }, []);

  return (
    <Layout>
      <SEO
        title={"Commande"}
        pathname={"/cart/checkout/"}
        description={""}
        banner={""}
        node={buildDates}
      />

      <div className={"container"}>
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">Commande</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to={"/"}>Accueil</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to="/cart/">Panier</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Commande
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <h2 className="h5 text-uppercase mb-4">Détails de la facturation</h2>
          {
            (state?.message || state?.errors?.global || state?.errors?.line_items) && (
              <div className={`alert alert-${alertType}`}>
                {state?.message || state?.errors?.global || state?.errors?.line_items}
              </div>
            )
          }
          <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <form
                action={`${`${websiteDetails.store_checkout_url}` || checkout_url}`}
                method='POST'
                onSubmit={onSubmit}
              >
                {/* BILLING ADDRESS */}
                <div className="row gy-3">
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="firstName">Prénom</label>
                    <input className="form-control form-control-lg" type="text" name="billing[first_name]" id="firstName" placeholder="Votre prénom" />
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.first_name}</span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="lastName">Nom de famille</label>
                    <input className="form-control form-control-lg" type="text" name="billing[last_name]" id="lastName" placeholder="Votre nom de famille" />
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.last_name}</span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="email">Email</label>
                    <input className="form-control form-control-lg" type="email" name="billing[email]" id="email" placeholder="e.g. mohammed@example.com" />
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.email}</span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="phone">Numéro de téléphone</label>
                    <input className="form-control form-control-lg" type="tel" name="billing[phone]" id="phone" placeholder="775354745" />
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.phone}</span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="address_1">Adresse</label>
                    <input className="form-control form-control-lg" type="text" name="billing[address_1]" id="address_1" placeholder="Votre adresse" />
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.address_1}</span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label text-sm text-uppercase" htmlFor="state">Wilaya</label>
                    <select
                      name="billing[state]"
                      id="state"
                      className={"form-control form-control-lg rounded-0"}
                      onChange={onEditShipping}
                      value={getShippingLocation()}
                      disabled={state.data?.lineItems.length === 0}
                    >
                      {
                        shippingState.data.map(({ node: { cost, location } }) => {
                          if (String(cost) === "0") return null;
                          return (
                            <option
                              value={location.code}
                              key={location.code}>
                              {location.name}
                            </option>
                          );
                        })
                      }
                    </select>
                    <span style={{ color: "#9a1111" }}>{state.errors?.billing?.state}</span>
                  </div>
                  <div className="col-lg-12">
                    <button className="btn btn-link text-dark p-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#alternateAddress">
                      <div className="form-check">
                        <input className="form-check-input" id="alternateAddressCheckbox" type="checkbox" />
                        <label className="form-check-label" htmlFor="alternateAddressCheckbox">Autre adresse de facturation</label>
                      </div>
                    </button>
                  </div>
                  {/* SHIPPING ADDRESS */}
                  <div className="collapse" id="alternateAddress">
                    <div className="row gy-3">
                      <div className="col-lg-6">
                        <label className="form-label text-sm text-uppercase" htmlFor="shipping-firstName">prénom</label>
                        <input className="form-control form-control-lg" type="text" name="shipping[first_name]" id="shipping-firstName" placeholder="Votre prénom" />
                        <span style={{ color: "#9a1111" }}>{state.errors?.shipping?.first_name}</span>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label text-sm text-uppercase" htmlFor="shipping-lastName">Nom de famille</label>
                        <input className="form-control form-control-lg" type="text" name="shipping[last_name]" id="shipping-lastName" placeholder="Votre nom de famille" />
                        <span style={{ color: "#9a1111" }}>{state.errors?.shipping?.last_name}</span>
                      </div>
                      <div className="col-lg-12">
                        <label className="form-label text-sm text-uppercase" htmlFor="shipping-address_1">Adresse</label>
                        <input className="form-control form-control-lg" type="text" name="shipping[address_1]" id="shipping-address_1" placeholder="Adresse" />
                        <span style={{ color: "#9a1111" }}>{state.errors?.shipping?.address_1}</span>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label text-sm text-uppercase" htmlFor="shipping-state">Wilaya</label>
                        <select
                          name="shipping[state]"
                          id="shipping-state"
                          className={"form-control form-control-lg rounded-0"}
                          onChange={onEditShipping}
                          value={getShippingLocation()}
                          disabled={state.data?.lineItems.length === 0}
                        >
                          {
                            shippingState.data.map(({ node: { cost, location } }: NodeEdges<ShippingZoneType>) => {
                              if (String(cost) === "0") return null;
                              return (
                                <option
                                  value={location.code}
                                  key={location.code}>
                                  {location.name}
                                </option>
                              );
                            })
                          }
                        </select>
                        <span style={{ color: "#9a1111" }}>{state.errors?.shipping?.state}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 form-group">
                    <button
                      className="btn btn-dark"
                      type="submit"
                      disabled={state.loading || shippingState.loading}
                    >
                      Passer la commande
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="card border-0 rounded-0 p-lg-4 bg-light">
                <div className="card-body">
                  <h5 className="text-uppercase mb-4">Votre commande</h5>
                  <ul className="list-unstyled mb-0">
                    {
                      state.data?.lineItems?.map(({ product_id, quantity }: LineItemType, index: number) => {
                        const product: Required<ProductType> = state.data.products[product_id];

                        return (
                          <React.Fragment key={`checkout-item-${product.slug}-${product_id}-${index}`}>
                            <li className="d-flex align-items-center justify-content-between">
                              <strong className="small fw-bold">{product.name}</strong>
                              <span className="text-muted small">{product.regular_price}DZD &times; {quantity}</span>
                            </li>
                            <li className="border-bottom my-2"></li>
                          </React.Fragment>
                        )
                      })
                    }
                    <li className="d-flex align-items-center justify-content-between">
                      <strong className="small fw-bold">Livraison</strong>
                      <span className="text-muted small">{getShippingTotal()}DZD</span>
                    </li>
                    <li className="border-bottom my-2"></li>
                    <li className="d-flex align-items-center justify-content-between">
                      <strong className="text-uppercase small fw-bold">Total</strong>
                      <span>{getLineItemsTotal() + getShippingTotal()}DZD</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

    </Layout>
  );
}
