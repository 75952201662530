import React from "react"
import { Helmet } from "react-helmet"

interface ExternalScriptsPropsTypes {
  gaMeasurementId?: string | undefined | null;
  facebookPixelId?: string | undefined | null;
}
export default function ExternalScripts({ gaMeasurementId, facebookPixelId }: ExternalScriptsPropsTypes) {
  if (!!gaMeasurementId || !!facebookPixelId) {
    return (
      <Helmet>
        {
          !!gaMeasurementId && (
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}></script>
          )}
        {
          !!gaMeasurementId && (
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaMeasurementId}');
              `}
            </script>
          )
        }
        {
          !!facebookPixelId && (
            <script>{`
              !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
              n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
              document,'script','https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${facebookPixelId}'); // Insert your pixel ID here.
              fbq('track', 'PageView');
            `}</script>
          )
        }
      </Helmet>
    );
  }
  return null;
}
