import React from "react";
import { Helmet } from "react-helmet";
import { FacebookOpenGraphPropTypes } from "./types";

function FacebookOpenGraph({ url, name, type, title, descriprion, image, locale }: FacebookOpenGraphPropTypes) {
  type = type ?? "website";

  return (
    <Helmet>
      {name && <meta property="og:site_name" content={name} />}
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descriprion} />
      {!!image && <meta property="og:image" content={image} />}
      {!!image && <meta property="og:image:alt" content={descriprion} />}
    </Helmet>
  );
}

export default FacebookOpenGraph;