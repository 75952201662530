import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Twitter from "./twitter";
import Facebook from "./facebook";
import { SEOPropTypes } from "./types";
import { getImage } from "gatsby-plugin-image";
import ExternalScripts from "./external-scripts.";

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/
const SEO = ({ title, description, banner, pathname, article, node, product }: SEOPropTypes) => {
  const {
    site,
    storeSocialMedia: {
      localFile,
      store_primary_settings: {
        store_analytics: {
          google_analytics: gaMeasurementId,
          facebook_pixel: facebookPixelId
        },
        store_ui: {
          store_logo
        }
      }
    }
  } = useStaticQuery(query);
  const {
    buildTime,
    siteMetadata: {
      siteUrl="https://zzenz.zzenz.store",
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
    },
  } = site
  title = title ?? null;
  description = description || "";
  banner = banner ?? null;
  pathname = pathname ?? null;
  article = article ?? false;
  node = node ?? null;

  const seo = {
    title: title || defaultTitle || site.author,
    description: description || defaultDescription || "",
    image: `${banner || ""}`,
    url: `${siteUrl}${pathname || ""}`,
    name: author
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the "author", "creator" with more data or another type (e.g. "Organization")
  const copyrightYear = (new Date()).getFullYear();
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: defaultTitle,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: copyrightYear,
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
    },
    datePublished: buildTime,
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  };

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: product?.name || "Homepage",
      },
      position: 1,
    },
  ];

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: copyrightYear,
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    });
  }

  let schemaProduct = null
  if (product) {
    schemaProduct = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": seo.title,
      "image": product?.images?.filter(img => img !== null)?.map(image => image.src) || [],
      "description": seo.description,
      "sku": product?.sku,
      "brand": {
        "@type": "Brand",
        "name": ""
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "reviewCount": "1"
      },
      "offers": {
        "@type": "Offer",
        "url": seo.url,
        "priceCurrency": "DZD",
        "price": product.regular_price,
        "priceValidUntil": (new Date(Date.now() + 3600000 * 24 * 30)).toLocaleString(),
        "itemCondition": "https://schema.org/NewCondition",
        "availability": `https://schema.org/${product.stock_status}`
      }
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: !!article ? 3 : 2,
    })
  }

  // add favicon
  let faviconUrl = null;
  if (!!localFile) {
    faviconUrl = getImage(localFile)?.images?.fallback?.src;
    faviconUrl = faviconUrl ?? store_logo;
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        {!!faviconUrl && <link rel={"icon"} type={"image/png"} href={faviconUrl} />}
        <link rel="canonical" href={seo.url} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="gatsby-starter" content="Gatsby Starter Prismic" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        {product && <script type="application/ld+json">{JSON.stringify(schemaProduct)}</script>}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        descriprion={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={seo.name}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        description={seo.description}
        username={seo.name}
      />
      <ExternalScripts
        gaMeasurementId={gaMeasurementId}
        facebookPixelId={facebookPixelId}
      />
    </>
  )
}

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
    storeSocialMedia: zzStoreWebsiteDetails {
      store_primary_settings {
        store_ui {
          store_logo
        }
        store_analytics {
          google_analytics,
          facebook_pixel
        },
      }
      localFile {
        childImageSharp {
          gatsbyImageData(
            height: 32
            width: 32
            formats: [PNG]
          )
        }
      }
    }
  }
`